export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    width: 2500,
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    width: 6000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    width: 3500,
  },
  {
    key: 'productName',
    label: 'field.product',
    localization: true,
    width: 6000,
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'image',
    width: 10000,
    exportKey: 'imageId',
    isImage: true,
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    width: 4000,
    type: 'date'
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
    options: ["field.notVisit", "field.visiting", "field.visited"]
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
    options: ["field.offRoute", "field.onRoute"]
  },
];
